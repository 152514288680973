import React, { useState, useEffect } from "react";
import DiscoverLuxHeader from "../../components/DiscoverLux/Header";
import { appendImage, createImage, createMarkup } from "../../utils";
import axios from "axios";
import { apiRoutes } from "../../constants/apiRoutes";
import Layout from "../../components/Layout";
// import { Helmet } from "react-helmet";
import Helmet from "../../components/Helmet";
import routes from "../../components/constants/routes";

const DLCurrentEvent = () => {
  const [currentSeason, setCurrentSeason] = useState({});
  const [header, setHeader] = useState(undefined);

  const pastEvent = currentSeason?.attributes?.past_event_dl?.data?.attributes;

  const fetchCurrentSeason = async () => {
    try {
      const { data } = await axios.get(apiRoutes.discoverluxCurrEvent);
      if (data) {
        setCurrentSeason(data.data);
      }
    } catch (error) {}
  };

  const fetchHeader = async () => {
    try {
      const { data } = await axios.get(apiRoutes.header);
      if (data) {
        setHeader(data.data.attributes);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchHeader();
    fetchCurrentSeason();
  }, []);

  useEffect(() => {
    appendImage("rich-editor");
  }, [currentSeason]);

  const seoData = {
    title: "Discover Luxembourg Current Season | Engage in City Exploration",
    description:
      "Participate in Discover Luxembourg's current season. Embark on city exploration, solve challenges, and discover the hidden gems of Luxembourg. Join the adventure now.",
    url: `${process.env.GATSBY_SITE_URL}${routes.DLCurrentEvent}/`,
  };

  return (
    <Layout>
      <>
        <Helmet
          title={seoData.title}
          description={seoData.description}
          url={seoData?.url}
        />

        <div className="discover-luxembourg">
          <div className="container-fluid p-mob">
            <div className="row">
              <div className="col-md-12">
                <div className="hero-section">
                  <img
                    src={createImage(
                      header?.discover_luxembourg_image?.data?.attributes?.url
                    )}
                    alt="The Discover Luxembourg "
                    width={"auto"}
                    height={"auto"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="head-tab mt-40">
                  <DiscoverLuxHeader />

                  {Object.values(currentSeason).length ? (
                    <div className="tab-content" id="nav-tabContent">
                      <div className="discover-currentevent">
                        <div className="current-event">
                          <section className="dl-byfoot">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-12">
                                  <h2>
                                    {pastEvent
                                      ? pastEvent?.title
                                      : currentSeason?.attributes?.title}
                                  </h2>
                                  {/* <div className="text-center mb-20">
                                  <img src="../../calendar.svg" />
                                </div>  */}
                                  <div
                                    className="rich-editor"
                                    dangerouslySetInnerHTML={createMarkup(
                                      pastEvent
                                        ? pastEvent?.description
                                        : currentSeason?.attributes
                                            ?.discoverluxembourg_description
                                    )}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <section className="dl-event-partners mt-50">
                            <div className="container">
                              {pastEvent?.event_partners?.data?.length ? (
                                <>
                                  <h2 className="mt-50 mb-20">
                                    Event Partners
                                  </h2>
                                  <div className="row event-partners mb-10 justify-content-center">
                                    {pastEvent?.event_partners?.data?.map(
                                      (el) => (
                                        <div className="col-md-3 col-6">
                                          <div className="event-partner-box">
                                            <img
                                              src={createImage(
                                                el?.attributes?.sponsor_image
                                                  ?.data?.attributes?.url
                                              )}
                                              alt={
                                                el?.attributes
                                                  ?.sponsor_image_alt_text
                                              }
                                              width={"auto"}
                                              height={"auto"}
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              ) : null}

                              {pastEvent?.station_sponsors?.data?.length ? (
                                <>
                                  <h2 className="mt-50 mb-20">
                                    Station Sponsors
                                  </h2>
                                  <div className="row event-partners justify-content-center">
                                    {pastEvent?.station_sponsors?.data?.map(
                                      (el) => (
                                        <div className="col-md-3 col-6">
                                          <div className="event-partner-box">
                                            <img
                                              src={createImage(
                                                el?.attributes?.sponsor_image
                                                  ?.data?.attributes?.url
                                              )}
                                              alt={
                                                el?.attributes
                                                  ?.sponsor_image_alt_text
                                              }
                                              width={"auto"}
                                              height={"auto"}
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              ) : null}

                              {pastEvent?.sponsors_media_patners?.data
                                ?.length ? (
                                <>
                                  <h2 className="mt-50 mb-20">
                                    Sponsors Media Partners
                                  </h2>
                                  <div className="row event-partners justify-content-center">
                                    {pastEvent?.sponsors_media_patners?.data?.map(
                                      (el) => (
                                        <div className="col-md-3 col-6">
                                          <div className="event-partner-box">
                                            <img
                                              src={createImage(
                                                el?.attributes?.sponsor_image
                                                  ?.data?.attributes?.url
                                              )}
                                              alt={
                                                el?.attributes
                                                  ?.sponsor_image_alt_text
                                              }
                                              width={"auto"}
                                              height={"auto"}
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              ) : null}

                              {pastEvent?.charity?.data?.length ? (
                                <>
                                  <h2 className="mt-50 mb-20">Charity</h2>
                                  <div className="row event-partners justify-content-center">
                                    {pastEvent?.charity?.data?.map((el) => (
                                      <div className="col-md-3 col-6">
                                        <div className="event-partner-box">
                                          <img
                                            src={createImage(
                                              el?.attributes?.sponsor_image
                                                ?.data?.attributes?.url
                                            )}
                                            alt={
                                              el?.attributes
                                                ?.sponsor_image_alt_text
                                            }
                                            width={"auto"}
                                            height={"auto"}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="text-center" style={{ margin: "15em 0" }}></p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default DLCurrentEvent;
